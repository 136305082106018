import axios from 'axios';
import { UrlHelper } from "@/helpers/url.helper"

export const adminService = {
    login,
    logout,
    loadProfile,
    getUserinfo,
    getUserId,

    setJwt,
    getJwt,

    forgotPassword,
    validToken,
    updatePassword,
    changePassword,

    downloadRasa,
    resetAcceptPolicy
};

async function login(email, password) {
    const url = UrlHelper.resolveApiUrl('admin/login');

    const result = await axios.post(url, {
        username: email,
        password: password,
        id: "0",
        salt: "string"
    });

    if (result.data.status > 0)
        return result.data.message;

    setJwt(result.data.data.access_token);

    return '';
}

function logout() {
    localStorage.clear()
    localStorage.clear()
}

async function loadProfile() {
    let url = UrlHelper.resolveApiUrl('admin/getuserinfo');

    try {
        let ret = await axios.get(url);
        if (ret.data.status == 0) {
            localStorage.setItem('userdata', JSON.stringify(ret.data.data));
            localStorage.setItem('userid', ret.data.data.id);
            localStorage.setItem('user', JSON.stringify(ret.data.data));

            return true;
        }
    }
    catch (err) {
        return false
    }

    return false;
}

function getUserinfo() {
    const userData = localStorage.getItem('user') || localStorage.getItem('userdata')
    if (userData)
        return JSON.parse(userData);

    return null;
}

function getUserId() {
    return localStorage.getItem('userid') || 0;
}

async function forgotPassword(email) {

    try {
        const url = UrlHelper.resolveApiUrl(`admin/forgotPassword?email=${email}`);
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function validToken(token, email) {

    try {
        const url = UrlHelper.resolveApiUrl(`admin/token`);
        const result = await axios.post(url, { token, email });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}


async function updatePassword(token, email, password) {

    try {
        const url = UrlHelper.resolveApiUrl(`admin/updatePassword`);
        const result = await axios.post(url, { token, email, password });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

function setJwt(jwt) {
    localStorage.setItem('jwt', jwt)
}

function getJwt() {
    return localStorage.getItem('jwt')
}

async function changePassword(obj) {
    const url = UrlHelper.resolveApiUrl(`admin/changePassword`);

    try {
        const result = await axios.post(url, obj);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function downloadRasa(clientId) {
    const url = UrlHelper.resolveApiUrl(`admin/downloadRasa?clientId=${clientId}`);

    try {
        const result = await axios.get(url, { responseType: "blob" })
        return result.data;
    }
    catch (err) {
        return null;
    }
}

async function resetAcceptPolicy() {
    try {
        const url = UrlHelper.resolveApiUrl(`admin/ResetAcceptPolicy`);
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}